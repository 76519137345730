import React from "react"
import { withPreview } from "gatsby-source-prismic"
import { graphql } from "gatsby"
import type { AllLayoutsType, UserType } from "@lesmills/gatsby-theme-common"

import {
  renderSEO,
  Layout,
  PublicRoute,
  ROUTES,
  LoadingIndicator,
  isBrowser,
} from "@lesmills/gatsby-theme-common"

const PrivacyPolicy = React.lazy(() => import("../components/PrivacyPolicy"))

type Props = {|
  data: {
    allLayouts: AllLayoutsType,
  },
  pageContext: {
    lang: string,
  },
|}

const PrivacyPolicyPage = (props: Props) => {
  const { pageContext, data } = props
  if (!data) return null

  const lang = pageContext.unPublishedLang || pageContext.lang

  const { prismicLayout = {}, prismicPrivacyPolicyPage = {} } = data
  const pageContent = prismicPrivacyPolicyPage.data || {}

  const renderPrivacyPolicyPage = ({ user, checkingSession }: UserType) => (
    <Layout
      data={prismicLayout.data}
      lang={lang}
      user={user}
      isLoading={checkingSession}
    >
      {renderSEO(pageContent, ROUTES(lang).PRIVACY_POLICY, lang)}
      {isBrowser && (
        <React.Suspense fallback={<LoadingIndicator />}>
          <PrivacyPolicy data={pageContent} />
        </React.Suspense>
      )}
    </Layout>
  )

  return (
    <PublicRoute component={renderPrivacyPolicyPage} {...props} lang={lang} />
  )
}

export const query = graphql`
  query($lang: String) {
    prismicLayout(lang: { eq: $lang }) {
      ...LayoutPublicPage
    }
    prismicPrivacyPolicyPage(lang: { eq: $lang }) {
      data {
        body {
          ... on PrismicPrivacyPolicyPageBodyPrivacyPolicy {
            items {
              policy_content {
                raw
                text
              }
              policy_id
              policy_title {
                raw
                text
              }
            }
            primary {
              back_to_top_button
              country_code
              country_nav_label {
                raw
                text
              }
              intro_policy_text {
                raw
                text
              }
              keypoint_content {
                raw
                text
              }
              keypoint_title {
                raw
                text
              }
              last_updated_label {
                raw
                text
              }
              privacy_policy_country_content {
                raw
                text
              }
              privacy_policy_country_title {
                raw
                text
              }
            }
          }
        }
        body1 {
          ... on PrismicPrivacyPolicyPageBody1AnnexTable {
            items {
              country_data {
                raw
                text
              }
              protection_authority_data {
                raw
                text
              }
            }
            primary {
              annex_content {
                raw
                text
              }
              annex_subtitle {
                raw
                text
              }
              annex_title {
                raw
                text
              }
              country_code
              country_header {
                raw
                text
              }
              protection_authority_header {
                raw
                text
              }
            }
          }
        }
        body2 {
          ... on PrismicPrivacyPolicyPageBody2PolicyNavigations {
            items {
              policy_navigation_label
              policy_navigation_url
            }
            primary {
              country_code
            }
          }
        }
        page_author {
          text
        }
        page_description {
          text
        }
        page_keywords {
          text
        }
        page_og_title {
          text
        }
        page_og_description {
          text
        }
        page_og_type {
          text
        }
        page_title {
          text
        }
        page_twitter_card {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_title {
          text
        }
        title {
          text
          raw
        }
      }
    }
  }
`

export default withPreview(PrivacyPolicyPage)
